<template>
  <ul class="flex items-center gap-2.5">
    <template
      v-for="(crumb, index) in crumbs"
      :key="index"
    >
      <li>
        <NuxtLink
          class="link-escape flex justify-center text-xs font-normal text-blue no-underline"
          :to="crumb.path"
        >
          {{ capitalizeFirst(crumb?.label) }}
        </NuxtLink>
      </li>
      <li v-if="crumbs.length -1 !== index">
        <IconsActionsChevron class="h-3 -rotate-90" />
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
const {locale} = useI18n();
const route = useRoute();
const router = useRouter();

const capitalizeFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

const crumbs = computed(() => {
  const params = route.path.substring(route.path.startsWith('/') ? 1 : 0).split('/');

  // Remove current language prefix
  const localePrefix = (params[0] === locale.value) ? `/${params.shift()}` : '';

  // Hide on home page
  if (!params.length || (params.length === 1 && params[0] === '')) {
    return;
  }

  let path = localePrefix;
  const crumbs = [{path: `${localePrefix}/`, label: 'home'}];

  params.forEach((param, i) => {
    path = `${path}/${param}`;

    const split = path.split('/');
    const label = (split.pop() || split.pop())?.replace(/-/g, ' ') ?? '';

    const routeExists = router.resolve(path).matched.length;
    const isLast = i === params.length - 1;

    if (path.includes('/vacatures')) {
      const replacedPath = path.replace('/vacatures', '#vacatures');

      crumbs.push({label, path: (routeExists && !isLast) ? replacedPath : ''});
      return crumbs;
    }

    crumbs.push({label, path: (routeExists && !isLast) ? path : ''});
  });

  return crumbs;
});
</script>
